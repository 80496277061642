﻿document.addEventListener("DOMContentLoaded", function () {
    const header = document.querySelector("header");
    const menuLinks = document.querySelectorAll(".menu-link");
    const menuButton = document.getElementById("menuButton");
    const menuMobile = document.getElementById("menuMobile");
    const menuClose = document.getElementById("menuClose");
    const maskMenu = document.getElementById("menu-mask");
    const acceptCookie = document.getElementById('acceptCookie');
    const msgCookie = document.getElementById('cookie-msg');

    acceptCookie.addEventListener('click', () => {
        localStorage.setItem("lgpd", "aceito");
        msgCookie.classList.add('hidden')
        maskMenu.classList.toggle("hidden");
    })
    
    if (localStorage.lgpd === 'aceito'){
        msgCookie.classList.add('hidden')
    }else {
        msgCookie.classList.remove('hidden')
        maskMenu.classList.toggle("hidden");
        
    }

    menuButton.addEventListener("click", () => {
        toggleMenu();
    });

    menuClose.addEventListener("click", () => {
        toggleMenu();
    });

    menuLinks.forEach(link => {
        link.addEventListener("click", (e) => {
            e.preventDefault(); // Impede o comportamento padrão do link
            const targetId = link.getAttribute("href").substring(1);
            const targetSection = document.getElementById(targetId);
            if (targetSection) {
                const offset = header.clientHeight;
                window.scrollTo({
                    top: targetSection.offsetTop - offset,
                    behavior: "smooth"
                });
                if (!menuMobile.classList.contains("-translate-x-full")) {
                    toggleMenu();
                }
            }
        });
    });

    //Close menu when click on mask
    maskMenu.addEventListener("click", () => {
        if (!menuMobile.classList.contains("-translate-x-full")) {
            toggleMenu();
        }
    });

    function toggleMenu() {
        menuMobile.classList.toggle("-translate-x-full");
        header.classList.toggle("hidden");
        maskMenu.classList.toggle("hidden");
        menuButton.style.display = menuMobile.classList.contains("-translate-x-full") ? "inline-flex" : "none";
        menuClose.style.display = menuMobile.classList.contains("-translate-x-full") ? "none" : "inline-flex";
    }
});

document.body.addEventListener('htmx:send', function (event) {
    if (event.detail.target.id === 'submit-button') {
        event.target.textContent = 'Loading';
        event.target.disabled = true;
    }
});

document.body.addEventListener('htmx:afterOnLoad', function (event) {
    if (event.detail.target.id === 'submit-button') {
        event.target.textContent = 'Concluído';
        event.target.disabled = true;
    }
});